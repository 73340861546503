<template>
    <div>
        <v-alert type="info" class="my-8">
            <v-row class="flex justify-center">
                <v-col cols="12" md="8" class="text-center">
                    <span class="font-bold">You are being redirected to Stripe to manage your subscription.</span>
                </v-col>
            </v-row>
        </v-alert>
    </div>
</template>

<script>
// import axios from 'axios'

export default {
    data() {
        return {
        }
    },

    // this lets the user manage their subscription
    // this is for when user visits the /membership page
    mounted() {
        this.redirectToStripe()
    },

    methods: {
        async redirectToStripe() {
            // try {
            //     let payload = {
            //         user_id: this.$session.get('user').id,
            //     }
            //     const response = await axios.post(process.env.VUE_APP_API_URL +'/membership/portal-session', payload, { headers: { "Content-Type": "application/json" } })
            //     // console.log(response.data)
            //     window.location.href = response.data
            // } catch (error) {
            //     console.log(error)
            //     this.$router.push('/404')
            // }
        }
    }
}
</script>

<style>

</style>